import fluidSVGPolyfill from "@threespot/fluid-svg-polyfill";

const init = () => {
  document.querySelectorAll("svg[data-fluid]").forEach(el => {
    new fluidSVGPolyfill(el, {
      moveClasses: true// move classes from SVG to wrapper
    });
  });
};

// Init on initial page load
init();
